<template>
  <div
    class="r-map-balloon pointer pa-4"
  >
    <div
      class="flex align-center mb-1"
    >
      <div
        class="flex align-center mr-3"
        :class="{'text-matrix': transport.online, 'text-metropolis': !transport.online }"
      >
        <svg
          class="mr-2"
          width="8"
          height="8"
          viewBox="0 0 8 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M4 8C6.20914 8 8 6.20914 8 4C8 1.79086 6.20914 0 4 0C1.79086 0 0 1.79086 0 4C0 6.20914 1.79086 8 4 8Z"
            :fill="transport.online ? '#57A003' : '#6F7A90'"
          />
        </svg>
        {{ transport.speed }} км/ч
      </div>
      <p
        class="сaprino opacity-72"
      >
        {{ transport.status }}
      </p>
    </div>
    <p
      class="roquefort mb-1"
    >
      {{ transport.model }}
    </p>
    <p
      class="сaprino opacity-72 mb-2"
    >
      {{ transport.owner }}
    </p>
    <div
      class="flex align-center"
    >
      <div
        class="mr-2 сaprino"
      >
        <span
          class="opacity-48"
        >
          {{ transport.gos_num }}
        </span>
      </div>
      <div
        class="plate matrix сaprino"
      >
        <span
          class="opacity-48"
        >
          {{ transport.boardNum }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BalloonTransport',
  props: {
    transport: {
      type: Object,
      default: () => {
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.plate {
  padding: 0 4px;
  border-radius: 2px;

  &.amadeus {
    background: #E3F3D8;
  }

  &.apocalypse {
    background: #E6EBF5;
  }
}

.r-map-balloon {
  width: 244px;

  &__img {
    width: 100%;
    height: 125px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 1;

    .backdrop {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      filter: blur(10px);
      z-index: -1;
    }

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  &__info {
    padding: 12px 16px 0;
  }
}

</style>
