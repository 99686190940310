<template>
  <div>
    <router-link
      :to="{
        name: 'edit',
        params: { id: uid }
      }"
    >
      <div class="obj-card">
        <div :class="[{ 'opacity-32': purgeDeadline, 'obj-card__img': img }]">
          <img
            v-if="img"
            :src="img"
            alt=""
            loading="lazy"
          >
          <r-icon
            class="obj-card__icon"
            v-else
            :icon="icon"
            size="32"
            fill="rocky"
          />
        </div>
        <div class="ml-4 obj-card__info flex-1">
          <div :class="['obj-card__title sulguni', { 'opacity-32': purgeDeadline }]">
            {{ title }}
          </div>
          <div :class="['obj-card__address mt-2 mozzarella', { 'opacity-32': purgeDeadline }]">
            {{ address }}
          </div>

          <div class="obj-card__counters flex align-center">
            <slot />
          </div>
        </div>

        <div class="flex align-center">
          <additional-menu v-if="!purgeDeadline">
            <ul class="obj-card__menu">
              <li
                class="sulguni align-items-center flex pointer"
                @click.stop.prevent="copyItem"
              >
                <r-icon
                  class="mr-3"
                  icon="copy"
                  fill="rocky"
                  size="16"
                />Дублировать
              </li>
              <li
                class="sulguni align-items-center flex mt-6 pointer"
                @click.stop.prevent="deleteItem"
              >
                <r-icon
                  class="mr-3"
                  icon="delete"
                  fill="fargo"
                  size="16"
                />Удалить
              </li>
            </ul>
          </additional-menu>
          <button
            class="obj-card__restore"
            v-else
            @click.stop.prevent="onRestore"
          >
            <r-icon
              icon="restore"
              fill="rocky"
              size="16"
            />
          </button>
        </div>
      </div>
    </router-link>
    <r-modal
      ref="modal"
      close-icon
      fullscreen
    />
  </div>
</template>

<script>
import AdditionalMenu from './AdditionalMenu.vue';
import DeleteModal from './DeleteModal';

export default {
  name: 'ObjectCard',
  components: { AdditionalMenu },
  props: {
    uid: {
      type: String,
      required: true
    },
    img: {
      type: [String, null],
      default: null
    },
    icon: {
      type: [String, null],
      default: 'buildings'
    },
    title: {
      type: String,
      required: true
    },
    address: {
      type: String,
      required: true
    },
    purgeDeadline: {
      type: [String, null],
      default: null
    }
  },
  methods: {
    deleteFunc() {
      this.$emit('delete-card');

      return true;
    },
    async onRestore() {},
    copyItem() {
      this.$router.push({ name: 'copy', params: { id: this.uid } });
    },

    deleteItem() {
      let _this = this;
      this.$refs.modal.openModal(DeleteModal, {
        id: Number(this.uid),
        actionType: 'deleteClean',
        title: this.title,
        address: this.address,
      }, {
        isCallbackAfterClose: _this.deleteFunc
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.obj-card {
  position: relative;
  display: flex;
  background-color: #fff;
  border: 1px solid #e4edfb;
  padding: 16px 22px 16px 16px;
  border-radius: 16px;
  &__img {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    background-color: var(--rir-arrival);
    width: 72px;
    height: 72px;
    border-radius: 8px;
    > img {
      height: 100%;
    }
  }
  &__icon {
    align-self: baseline;
  }
  &__info {
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
  &__title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &__address {
    opacity: 0.72;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &__counters {
    margin-top: 14px;
  }
  &__restore {
    background-color: transparent;
    cursor: pointer;
  }
  .pointer {
    cursor: pointer;
  }
}
.opacity-32 {
  opacity: 0.32;
}
.align-center {
  align-items: center;
}
</style>
