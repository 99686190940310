<template>
  <div class="loader flex align-center justify-center">
    <r-spinner />
  </div>
</template>

<script>
export default {
  name: 'LoaderModel'
};
</script>

<style scoped lang="scss">
.loader {
  z-index: 10000;
  padding: 40px;
  width: 100%;
  height: 100%;
  background-color: white;
  position: fixed;
  top: 0;
  left: 0;
}
</style>
