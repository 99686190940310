<template>
  <div
    :class="['rir-map', { 'rir-map--fixed': !isCollapsed }]"
    ref="map"
  >
    <div
      v-if="search"
      class="rir-map__search"
    >
      <r-input
        class="cleaning__input-map-search"
        label="Поиск по адресу"
        :value="value"
        @input="$emit('input', $event)"
      >
        <template #after>
          <r-icon
            icon="search"
            fill="pianist"
            size="16"
          />
        </template>
      </r-input>
    </div>
    <yandex-map
      v-if="isYmapsReady"
      :coords="center || $cityCenter"
      :zoom.sync="mapZoom"
      @click="onMapClick"
      @contextmenu="$emit('onRightClick', $event)"
      :options="mapOptions"
      :controls="mapControls"
      @map-was-initialized="onMapInit"
      @markers-was-add="$emit('markersWasAdd', $event)"
      @markers-was-change="$emit('markersWasChange', $event)"
      :cluster-options="clusterOptions"
    >
      <slot />
    </yandex-map>

    <div
      v-if="legend"
      class="rir-map__legend"
    >
      <div
        class="flex"
      >
        <div
          class="flex align-center"
        >
          <span
            class="legend__color lebowski--bg mt-1"
          />
          <span
            class="briscola opacity-72"
          >
            Ожидается уборка
          </span>
        </div>

        <div
          class="ml-2 flex align-center"
        >
          <span
            class="legend__color rocky--bg mt-1"
          />
          <span
            class="briscola opacity-72"
          >
            Идёт уборка
          </span>
        </div>

        <div
          class="ml-2 flex align-center"
        >
          <span
            class="legend__color matrix--bg mt-1"
          />
          <span
            class="briscola opacity-72"
          >
            Уборка завершена
          </span>
        </div>
      </div>
    </div>

    <div
      v-show="control"
      class="rir-map__controls"
    >
      <r-button-simple
        style="display: block;"
        v-if="collapseBtn"
        class="mb-4 amelie"
        :icon="isCollapsed ? 'fullscreen' : 'exit-fullscreen'"
        fill="rocky"
        icon-size="20"
        @click="onCollapseMap"
        type="light"
        size="larishae"
        title=""
      />
      <r-button-simple
        style="display: block;"
        type="light"
        size="larishae"
        icon="add"
        class="amelie"
        fill="rocky"
        icon-size="20"
        @click="onZoom(1)"
        title=""
      />
      <r-button-simple
        style="display: block;"
        type="light"
        size="larishae"
        class="mt-1 mb-4 amelie"
        icon="remove"
        fill="rocky"
        icon-size="20"
        @click="onZoom(-1)"
        title=""
      />
      <r-button-simple
        style="display: block;"
        type="light"
        size="larishae"
        v-if="locationBtn"
        icon="location"
        icon-size="20"
        @click="onLocation"
        title=""
      />
    </div>
  </div>
</template>

<script>
import { loadYmap } from '../../plugins/vue-yandex-maps/vue-yandex-maps.umd';
import BalloonCard from '../balloons/BalloonCard.vue';

export default {
  name: 'RirMap',
  components: {
    BalloonCard
  },
  props: {
    search: {
      type: Boolean,
      default: false
    },
    value: {
      type: [String, null],
      default: null
    },
    control: {
      type: Boolean,
      default: true
    },
    collapseBtn: {
      type: Boolean,
      default: true
    },
    locationBtn: {
      type: Boolean,
      default: false
    },
    legend: {
      type: Boolean,
      default: true
    },
    center: {
      type: Array
    },
    zoom: {
      type: Number,
      default: 14
    },
    maxZoom: {
      type: Number,
      default: 22
    },
    minZoom: {
      type: Number,
      default: 10
    }
  },
  data() {
    return {
      isYmapsReady: false,
      mapControls: [],
      mapOptions: {
        suppressMapOpenBlock: true,
        suppressObsoleteBrowserNotifier: true,
        yandexMapDisablePoiInteractivity: true
      },

      clusterOptions: {
        main: {
          clusterOpenBalloonOnClick: false,
          clusterIcons: [
            {
              href: `${this.$S3_COMMON}/icons/map/cluster.svg`,
              size: [56, 56],
              offset: [0, 0]
            }
          ]
        }
      },
      mapZoom: this.zoom,
      mapInstanse: null,
      timeout: null,
      isCollapsed: true
    };
  },

  async mounted() {
    const settings = {
      coordorder: 'latlong'
    };

    !window.ymaps && (await loadYmap(settings));
    this.isYmapsReady = true;
  },

  beforeDestroy() {
    this.mapInstanse && this.mapInstanse.destroy();
  },

  methods: {
    onCollapseMap() {
      this.isCollapsed = !this.isCollapsed;
      this.$nextTick(() => {
        this.mapInstanse.container.fitToViewport();
      });
    },

    onMapInit(e) {
      this.mapInstanse = e;
      this.$emit('onInit', this.mapInstanse);
      this.setObserver();
      this.mapInstanse.options.set('openBalloonOnClick', false);
    },

    onZoom(val) {
      const newZoom = this.mapZoom + val;
      if (newZoom <= this.maxZoom && newZoom >= this.minZoom) this.mapZoom = newZoom;
    },

    onMapClick(e) {
      const coords = e.get('coords');
      this.coords = coords;
      this.mapInstanse.balloon.isOpen() && this.mapInstanse.balloon.close();
      this.$emit('click', coords);
    },
    setObserver() {
      const myObserver = new ResizeObserver(entries => {
        entries.forEach(entry => {
          if (this.timeout) clearTimeout(this.timeout);
          this.timeout = setTimeout(() => {
            this.mapInstanse.container.fitToViewport();
          }, 50);
        });
      });

      myObserver.observe(this.$refs.map.parentElement);
    }
  }
};
</script>

<style lang="scss" scoped>
.rir-map {
  height: 100%;
  width: 100%;
  position: relative;
  border-radius: 24px;
  overflow: hidden;

  &__search {
    position: absolute;
    border-radius: 8px;
    z-index: 10;
    top: 16px;
    left: 16px;
    display: flex;
    width: 255px;
    background-color: #fff;
    box-shadow: 0 4rem 16rem rgb(4 21 62 / 16%);
    > * {
      background-color: #fff;
    }
  }

  &__controls {
    display: block !important;
    position: absolute;
    top: 16px;
    right: 16px;
    > * {
      width: 40px;
      height: 40px;
      box-shadow: 0 4px 16px rgb(4 21 62 / 16%);
    }

    &--fixed {
      position: fixed;
      right: 16px;
      top: 16px;
      z-index: 10001;
    }
  }

  &--fixed {
    background-color: #ccc;
    border-radius: unset;
    position: fixed;
    height: 100% !important;
    top: 0;
    left: 0;
    z-index: 5;
  }

  &__legend {
    padding: 8px 12px;
    background: var(--rir-amelie);
    position: absolute;
    left: 16px;
    bottom: 16px;
    border-radius: 4px;
  }
}

.legend {
  &__color {
    margin-right: 6px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
  }
}

@media screen and (max-width: 1023px) {
  .rir-map {
    &__search {
      display: none;
    }
  }
}

::v-deep .ymap-container {
  width: 100%;
  height: 100%;
}
::v-deep .ymaps-2-1-79-map {
  max-height: 100%;
  overflow: hidden;
}

::v-deep .ymaps-2-1-79-controls-pane {
  display: none;
}

::v-deep .ymaps-2-1-79-balloon {
  bottom: calc(99% - -14px);
  top: unset;
  box-shadow: 0px 9px 28px rgb(17 48 121 / 18%);
  border-radius: 16px 16px 16px 0px;
  overflow: hidden;
  transform: translate(66px, -10px);
  padding: 0;
  margin: 0;
}

::v-deep .ymaps-2-1-79-balloon__tail,
.ymaps-2-1-79-balloon__tail:after {
  display: none;
}

::v-deep .ymaps-2-1-79-balloon__close-button {
  display: none;
}

::v-deep .ymaps-2-1-79-balloon__content {
  margin: 0 !important;
  padding: 0 !important;
}
</style>

<style>
.ymaps-2-1-79-default-cluster {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ymaps-2-1-79-default-cluster > * {
  color: #ffffff;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
}

.ymaps-2-1-79-float-button {
  display: none !important;
}
</style>
