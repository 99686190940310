<template>
  <div>
    <div class="objects__list mt-6">
      <div
        style="cursor: pointer"
        v-for="item in filteredObjects"
        :key="item.id"
      >
        <card
          ref="Card"
          :address="item.address"
          :house="item.dayF"
          :service-title="item.title"
          :photo0="item.photo0"
          :active-tab-id="activeTabId"
          :id="item.id"
          class="color-card"
        >
          <div class="flex">
            <div class="mr-3 float flex mozzarella">
              <r-icon
                v-if="getStatus(item.dayF, item.dayFEnd, item.timeTo, item.timeFrom) == 'done'"
                icon="selected"
                fill="rocky"
                class="mr-2"
                style="float: left"
                size="16"
              />
              <r-icon
                v-if="getStatus(item.dayF, item.dayFEnd, item.timeTo, item.timeFrom) == 'progress'"
                icon="clock"
                class="mr-2"
                fill="rocky"
                size="16"
                style="float: left"
              />
              <r-icon
                v-if="getStatus(item.dayF, item.dayFEnd, item.timeTo, item.timeFrom) == 'wait'"
                icon="waiting"
                class="mr-2"
                fill="rocky"
                size="16"
                style="float: left"
              />
              <div class="text__date rocky--text">
                {{ item.dayF != '-' ? dateFilter(item.dayF) : item.dayF }}
              </div>
            </div>
            <div class="mr-1 float flex mozzarella text__date rocky--text">
              <r-icon
                class="mr-2 float"
                icon="message"
                size="16"
                fill="rocky"
              />
              {{ item.commentsAmount }}
            </div>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import Card from '@/components/ObjectCardEvent.vue';
import Loader from '@/components/Loader.vue';
import moment from 'moment';
import { wordMatch } from '../helpers/utils';

export default {
  name: 'loadEvent',
  components: {
    Card,
    Loader
  },
  props: {
    activeTabId: {
      type: String,
      default: ''
    },
    checkHouse: {
      type: Number,
      default: null
    },
    isSearch: {
      type: String,
      default: ''
    },
    isSearchStatus: {
      type: String,
      default: ''
    },
    selectedCategoryMer: {
      type: String,
      default: ''
    },
    selectedCategoryMerTo: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      countOnPublic: null,
      countOnModerate: null,
      countGis: 0,
      timeTo: null,
      timeFrom: null
    };
  },
  computed: {
    allObjects() {
      let _this = this;
      if (!this.$store.state?.active?.all.length) return [];

      const objects = this.$store.state.active.all;
      return objects
        .map(el => {
          if (el?.id) {
            let timeTo = null;
            let timeFrom = null;
            if(el.eventForm?.time) {
              const time = el.eventForm?.time.split(' - ');
              timeTo = this.formatDateHour(time[0]);
              timeFrom = this.formatDateHour(time[1]);
            }
            return {
              id: el.id,
              timeTo: timeTo,
              timeFrom: timeFrom,
              address: el.eventForm ? el.eventForm.address : '-',
              dayF: el.eventForm?.datesExtra ? el.eventForm.datesExtra[0] : '-',
              dayFEnd: el.eventForm?.datesExtra ? el.eventForm.datesExtra[el.eventForm.datesExtra.length - 1] : '-',
              title: el.title,
              lan: el.lat ? el.lat : 47.521196,
              lng: el.lng ? el.lng : 42.180914,
              approved: el.approved,
              photo0: el.photo0,
              commentsAmount: el.commentsAmount
            };
          }
        })
        .sort((a, b) => (b.count < 20) && a.address.localeCompare(b.address));
    },
    // Фильтрация списка домов
    filteredObjects() {
      const v = this;
      let list = this.allObjects;
      if (v.activeTabId == 'moderation') {
        list = list.filter(
          el => (el.approved == 1)
        );
      }
      if (v.activeTabId == 'moderationMod') {
        list = list.filter(
          el => (el.approved == 0)
        );
      }

      if (list && (this.selectedCategoryMer || this.selectedCategoryMerTo)) {
        list = list.filter(el => moment(new Date(el.dayF)).set({
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0
        }).isBetween(moment(this.selectedCategoryMer ? new Date(this.selectedCategoryMer) : new Date(null)).set({
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0
        }), moment(this.selectedCategoryMerTo ? new Date(this.selectedCategoryMerTo) : new Date()).set({
          hour: 23,
          minute: 59,
          second: 59,
          millisecond: 59
        })) === true);
      }

      if (v.selectedCategoryStatus && v.activeTabId == 'moderation') {
        list = list.filter(el => v.getCleaningStatus(el.dayF) === v.selectedCategoryStatus);
      }
      if (v.isSearchStatus && v.activeTabId == 'moderation') {
        list = list.filter(el => this.getCleaningStatus(el.dayF) === v.isSearchStatus);
      }

      if (v.isSearch) {
        list = list.filter(el => wordMatch(el.address.toLowerCase(), v.isSearch.toLowerCase()) <= 0.6);
      }

      return list;
    },
    // пред загрузка
    isLoading() {
      return this.$store.state.isObjectLoading;
    }
  },
  methods: {
    dateFilter(e) {
      const date = new Date(e);
      let dd = date.getDate();
      if (dd < 10) dd = `0${dd}`;
      let mm = date.getMonth() + 1;
      if (mm < 10) mm = `0${mm}`;
      const yy = date.getFullYear();

      return `${dd}.${mm}.${yy}`;
    },
    onChangePeriod(e) {
      const toDay = new Date(e);
      const options = {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric'

      };
      const day = toDay.toLocaleString('ru-Ru', options);

      return day;
    },
    getStatus(date, dateEnd, timeTo, timeFrom) {
      const toDay = new Date().toJSON().substring(0, 10); // 'yyyy-mm-dd'
      let from = 9;
      let to = 18;
      if (timeTo && timeFrom) {
        from = timeTo.split(':')[0];
        to = timeFrom.split(':')[0];
      }
      let status = 'wait';
      const hours = new Date().getHours();
      if (toDay >= date && toDay <= dateEnd) {
        status = 'progress';
        if (toDay == date) {
          if (hours < from) {
            status = 'wait';
          }
        }

        if (toDay == dateEnd) {
          if (hours < from) {
            status = 'progress';
          }
          if (hours > to) {
            status = 'done';
          }
        }
      }

      if (new Date(dateEnd) < new Date()) {
        status = 'done';
      }

      if(date == '-') {
        status = 'done';
      }

      return status;
    },
    formatDateHour(time) {
      let hh = time.split(':')[0];
      if (hh < 10) hh = hh.substring(1);
      const mm = time.split(':')[1];

      return `${hh}:${mm}`;
    },
    linkClick(id) {
      const r = this.$router.resolve({
        name: 'clear-edit',
        params: { id, bul: false }
      });
      window.location.assign(r.href);
    }

  }
};
</script>

<style lang="scss" scoped>
.text__date {
  color: #0e1420;
  font-size: 13px;
  line-height: 16px;
  font-weight: 500;
}

.objects {
  &__list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 4px;

    > * {
      min-width: 0;
    }
  }
}

.float-right {
  margin: 0 0 0 auto;
}

.color-card {
  color: #0e1420;
}

.icon-placeholder {
  display: inline-block; /* Строчно-блочный элемент */
  position: relative;
}

.icon-placeholder:hover::after {
  content: attr(data-title); /* Выводим текст */
  position: absolute; /* Абсолютное позиционирование */
  left: 0%;
  top: 150%; /* Положение подсказки */
  z-index: 1200; /* Отображаем подсказку поверх других элементов */
  background: #FFFFFF;
  border-radius: 12px;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: #04153E;
  padding-top: 4px;
  padding-left: 8px;
  padding-bottom: 4px;
  padding-right: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}
</style>
