var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('r-uploader',{attrs:{"title":_vm.title,"sub-title":_vm.subTitle,"accept":_vm.acceptFile,"callback":_vm.uploadFile,"max-files":_vm.countFile,"button-simple-option":{
      icon: 'delete',
      color: 'fargo'
    }},model:{value:(_vm.filesUpload),callback:function ($$v) {_vm.filesUpload=$$v},expression:"filesUpload"}}),_c('div',{staticClass:"mt-6 flex flex-direction-column"},_vm._l((_vm.value),function(file,index){return _c('r-file',{key:index,staticClass:"mb-6",attrs:{"file":file,"title":file.name,"button-simple-option":{
        icon: 'delete',
        color: 'fargo'
      }},on:{"clickPreview":function($event){return _vm.expandPhoto()},"actions":_vm.deleteFile}})}),1),_c('transition',{attrs:{"name":"fade"}},[(_vm.expandImg)?_c('expand-photo-wrapper',{attrs:{"src":_vm.value[0].url},on:{"close":function($event){_vm.expandImg = false}}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }