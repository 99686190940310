<template>
  <div
    class="flex"
  >
    <div
      v-show="upload"
      class="upload"
    >
      <div>
        <div
          @click="upload = !upload"
        >
          <r-icon
            class="mr-2 margin-right"
            icon="close"
            size="20"
            fill="rocky"
          />
        </div>
        <div
          class="parent align-center"
        >
          <div
            class="container-upload"
          >
            <img
              :src="item"
              width="1000px"
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ImageModal',
  props: {
    item: {
      type: String
    }
  },
  data() {
    return {
      upload: false
    };
  },
  methods: {
    showImages() {
      this.upload = true;
    }
  }
};
</script>
<style scoped>
.align-center {
  align-items: center;
}

.upload {
  background-color: white;
  z-index: 999;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}

.parent {
  display: block;
  position: absolute;
  width: 40%;
  height: 400px;
  top: 30%;
  margin-left: calc(50% - 500px);
  margin-top: -150px;
}

.container-upload {
  width: 87%;
  height: 300px;
}

.margin-right {
  top: 10px;
  right: 10px;
  z-index: 1000;
  cursor: pointer;
  position: absolute;
}
</style>
