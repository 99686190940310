<template>
  <div>
    <div
      class="obj-card"
      @click="linkClick(id)"
    >
      <div class="mr-5">
        <div
          v-if="photo0 != ''"
          style="margin-top: 7px;width:58px;height:58px;overflow:hidden;border-radius: 8px;"
        >
          <img
            :src="photo0"
            style="height: 58px;border-radius: 8px;"
          >
        </div>
        <picture
          class="ml-auto mr-auto mt-4"
          v-else
          style="display: table"
        >
          <r-icon
            icon="city-clean"
            size="32"
            fill="rocky"
          />
        </picture>
      </div>
      <div
        class="flex-1"
        style="overflow: hidden"
      >
        <div class="obj-card__info">
          <div :class="['obj-card__title sulguni', { 'opacity-32': purgeDeadline }]">
            {{ serviceTitle }}
          </div>
        </div>
        <div
          :class="['obj-card__counters flex ' +
            'obj-card__address mozzarella style-marin-house', { 'opacity-32': purgeDeadline }]"
        >
          <div class="float-left mr-2 mozzarella">
            {{ address }}
          </div>
        </div>

        <div class="obj-card__counters flex align-center">
          <slot />
        </div>
      </div>
      <div
        class="flex align-center"
        v-if="activeTabId === 'moderationMod'"
      >
        <additional-menu>
          <ul class="card__menu">
            <li
              class="sulguni align-items-center flex pointer opacity-72"
              @click.stop.prevent="onDelete(id)"
            >
              <r-icon
                class="mr-3"
                icon="delete"
                fill="fargo"
                size="16"
              />
              Удалить
            </li>
          </ul>
        </additional-menu>
      </div>
    </div>
    <r-modal
      ref="modal"
      close-icon
      fullscreen
    />
  </div>
</template>

<script>
import AdditionalMenu from './AdditionalMenu';
import DeleteModal from './DeleteModal';

export default {
  components: {
    AdditionalMenu
  },
  props: {
    activeTabId: String,
    id: [String, Number],
    address: {
      type: String,
      required: true
    },
    house: {
      type: String,
      default: ''
    },
    serviceTitle: {
      type: String,
      default: ''
    },
    purgeDeadline: {
      type: [String, null],
      default: null
    },
    photo0: {
      type: String,
      default: ''
    }
  },
  methods: {
    linkClick(id) {
      const r = this.$router.resolve({
        name: 'clear-edit',
        params: { id, bul: false }
      });
      window.location.assign(r.href);
    },
    async onDelete(id) {
      await this.$refs.modal.openModal(DeleteModal, {
        id: Number(id),
        actionType: 'setDelete',
        address: this.address
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.obj-card {
  position: relative;
  display: flex;
  background-color: #fff;
  border: 1px solid #e4edfb;
  padding: 16px 22px 16px 16px;
  border-radius: 16px;

  &__img {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    background-color: var(--rir-arrival);
    margin-right: 12px;
    width: 72px;
    height: 72px;
    border-radius: 8px;

    > img {
      height: 100%;
    }
  }

  &__info {

  }

  &__title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__address {
    opacity: 0.72;
  }

  &__counters {
    margin-top: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

  }

  &__restore {
    background-color: transparent;
    cursor: pointer;
  }

  .pointer {
    cursor: pointer;
  }
}

.opacity-32 {
  opacity: 0.32;
}

.style-marin-house {
  margin-top: 6px;
}
.float-left {
  float: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.align-center {
  align-items: center;
}
</style>
