import './set-public-path';
import Vue from 'vue';
import singleSpaVue from 'single-spa-vue';

import rir from '@/plugins/RirLib';
import moment from 'moment';
import App from './App.vue';
import router from './router';
import store from './store';
import ymapPlugin from './plugins/vue-yandex-maps/vue-yandex-maps.umd';

Vue.prototype.moment = moment;
const mapSettings = {
  lang: 'ru_RU',
  coordorder: 'latlong',
  version: '2.1'
};

Vue.prototype.$appeal = {
  layout: 'default#image',
  imageHref: 'https://storage.rosatom.city/platform-prod/volgodonsk/common/vue/icons/map/problem_type_2.svg'
};

Vue.prototype.$markerIconTwo = {
  layout: 'default#image',
  imageHref: process.env.VUE_APP_MAP_MARKER_ICON
};

Vue.prototype.$storage = window?.__CONFIG__?.storage || null;
Vue.prototype.$cityName = window?.__CONFIG__?.cityName || '';
Vue.prototype.$cityCenter = window?.__CONFIG__?.cityCenter?.latLng
  || process.env.VUE_APP_CITY_CENTER.split(',').map(el => +el);

const S3_COMMON = window?.__CONFIG__?.storage
  ? `${window.__CONFIG__.storage}/common/vue`
  : process.env.VUE_APP_S3_COMMON_PATH;

Vue.prototype.$S3_COMMON = S3_COMMON;
const mapMarkers = {
  cleaningTransport: "data:image/svg+xml;charset=UTF-8,%3csvg width='52' height='24' viewBox='0 0 52 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M0 8C0 3.58172 3.58172 0 8 0H40C46.6274 0 52 5.37258 52 12V24H8C3.58172 24 0 20.4183 0 16V8Z' fill='%2357A003'/%3e%3cpath d='M14 13.5C14 14.0523 13.5523 14.5 13 14.5C12.4477 14.5 12 14.0523 12 13.5C12 12.9477 12.4477 12.5 13 12.5C13.5523 12.5 14 12.9477 14 13.5Z' fill='white'/%3e%3cpath d='M19 14.5C19.5523 14.5 20 14.0523 20 13.5C20 12.9477 19.5523 12.5 19 12.5C18.4477 12.5 18 12.9477 18 13.5C18 14.0523 18.4477 14.5 19 14.5Z' fill='white'/%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M15 4C14.4477 4 14 4.44772 14 5H11.6805C10.7028 5 9.8684 5.70683 9.70767 6.6712L9.31954 9H9C8.44772 9 8 9.44772 8 10C8 10.5523 8.44772 11 9 11V15C9 15.0441 9.00285 15.0875 9.00837 15.13C8.76241 15.2696 8.5793 15.5113 8.52083 15.8044L8.04169 18.2066C7.8566 19.1346 8.56646 20 9.51271 20H22.4948C23.4422 20 24.1523 19.1327 23.9653 18.2039L23.4818 15.8026C23.4228 15.5091 23.2387 15.2674 22.9918 15.1284C22.9972 15.0864 23 15.0435 23 15V11C23.5523 11 24 10.5523 24 10C24 9.44772 23.5523 9 23 9H22.6805L22.2923 6.6712C22.1316 5.70683 21.2972 5 20.3195 5H18C18 4.44772 17.5523 4 17 4H15ZM11 15V12H21V15H17.5015C16.9492 15 16.5015 15.4477 16.5015 16C16.5015 16.5523 16.9492 17 17.5015 17H21.6828L21.8841 18H10.1223L10.3218 17H14.5015C15.0538 17 15.5015 16.5523 15.5015 16C15.5015 15.4477 15.0538 15 14.5015 15H11ZM11.1805 10H20.8195L20.3195 7L11.6805 7L11.1805 10Z' fill='white'/%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M40 2C34.4772 2 30 6.47715 30 12C30 17.5228 34.4772 22 40 22H50V12C50 6.47715 45.5228 2 40 2ZM40 24H50H52V22V12C52 5.37258 46.6274 0 40 0C33.3726 0 28 5.37258 28 12C28 18.6274 33.3726 24 40 24ZM36 12C36 9.79086 37.7909 8 40 8C42.2091 8 44 9.79086 44 12C44 14.2091 42.2091 16 40 16C37.7909 16 36 14.2091 36 12Z' fill='white'/%3e%3c/svg%3e "
};
Vue.prototype.$markerIcon = (iconName = 'marker') => ({
  layout: 'default#image',
  imageHref: mapMarkers[iconName] || `${S3_COMMON}/icons/map/${iconName}.svg`
});

Vue.use(ymapPlugin, mapSettings);
Vue.config.productionTip = false;

const vueLifecycles = singleSpaVue({
  Vue,
  appOptions: {
    render(h) {
      return h(App, {
        props: {
          // single-spa props are available on the "this" object.
          // Forward them to your component as needed.
          // https://single-spa.js.org/docs/building-applications#lifecyle-props
          // if you uncomment these, remember to add matching prop definitions
          // for them in your App.vue file.
          /*
          name: this.name,
          mountParcel: this.mountParcel,
          singleSpa: this.singleSpa,
          */
        }
      });
    },
    router,
    store,
    $rir: rir
  }
});

export const { bootstrap } = vueLifecycles;
export const { mount } = vueLifecycles;
export const { unmount } = vueLifecycles;
