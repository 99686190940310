<template>
  <div id="app">
    <router-view />
    <r-notification />
  </div>
</template>

<script>
export default {
  name: 'CultureAdminApp',

  beforeCreate() {
    // Удаляем script от пред. приложения
    document.getElementById('vue-yandex-maps')
      && document.getElementById('vue-yandex-maps').remove();

    // Удаляем ymaps
    window.ymaps && delete window.ymaps;
  }
};
</script>

<style>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}
.RBulb__badge.fargo.marina.eluno.title.block {
  background-color: unset !important;
}
.RButtonSimple.RModal__close.larishae.transparent.rocky {
  background-color: unset !important;
}
.RButtonAction.rocky.sulguni {
  background-color: unset !important;
}
</style>
