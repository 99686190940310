import Vue from 'vue';
import Rir from '@rir/vue-library';
// import '@rir/vue-library/dist/RirLib.css';
// import Rir from './vue-library/RirLib.umd.min';
//import './vue-library/RirLib.css';

Vue.use(Rir);

const options = {};

export default new Rir(options);

/*
import library from './ui/RirUI.umd.min';
import('./ui/RirUI.css');

export default {
  async install(Vue) {
    const { Components, Directives, Mixins } = library;
    Object.keys(Components).forEach(name => {
      Vue.component(Components[name].name, Components[name]);
    });
    Object.entries(Directives).forEach(directive => {
      Vue.use(directive);
    });
    Object.values(Mixins).forEach(mixin => {
      Vue.mixin(mixin);
    });
  }
};
*/
