<template>
  <div
    class="flex"
  >
    <div
      class="upload"
    >
      <div>
        <div
          class="parent align-center"
        >
          <div
            class="container-upload ml-auto mr-auto"
          >
            <r-icon
              icon="warning"
              fill="fargo"
              size="56"
            />
            <h1
              class="mb-4 mt-7 camembert"
            >
              Удалить уборки?
            </h1>
            <div
              class="mb-8 parmigiano troy--text"
            >
              Все данные за период c {{ dateFilter(periodTo) }}  по {{ dateFilter(periodFrom) }}, будут удалены.
            </div>
            <div
              class="mt-2 flex"
            >
              <r-button
                title="Не удалять"
                class="flex-1 mr-4"
                :type="'secondary'"
                @click="close()"
              />
              <r-button
                title="Да, удалить"
                class="flex-1"
                @click.native.stop.prevent="Delete"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Api from '../../../api/PlaceApi';

export default {
  name: 'DeletePeriodModal',
  props: {
    periodTo: String,
    periodFrom: String
  },
  data() {
    return {
      upload: false
    };
  },
  methods: {
    dateFilter(e) {
      const date = new Date(e);
      let dd = date.getDate();
      if (dd < 10) dd = `0${dd}`;
      let mm = date.getMonth() + 1;
      if (mm < 10) mm = `0${mm}`;
      const yy = date.getFullYear();

      return `${dd}.${mm}.${yy}`;
    },
    close() {
      document.querySelectorAll('.RModal__close').forEach(item => {
        item.click();
      });
    },
    async Delete() {
      const api = new Api();
      await api.deleteDate(this.periodFrom, this.periodTo).then(result => {
        const r = this.$router.resolve({
          name: 'index'
        });
        if (!result?.error) {
          window.location.assign(r.href);
        }
      }).catch(err => {
        console.log('err', err);
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.container-upload {
  width: 800px;
}

.upload {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
}
</style>
