<template>
  <div class="edit__form">
    <div
      v-if="isPeople"
      :key="componentKey"
    >
      <h2 class="taleggio mb-6">
        Жители
      </h2>
      <div
        class="title-conteiner"
        v-if="typeof items['population']['total'] !== 'undefined'"
      >
        <div class="title-params">
          Прописанные
        </div>
        <div class="title-params-item flex sulguni">
          {{ items['population']['total'] }}
        </div>
      </div>
      <div
        class="title-conteiner flex sulguni"
        v-if="typeof items['population']['in'] !== 'undefined'"
      >
        <div class="title-params">
          Временно прибывшие
        </div>
        <div class="title-params-item flex sulguni">
          {{ items['population']['in'] }}
        </div>
      </div>
      <div
        class="title-conteiner flex sulguni"
        v-if="typeof items['population']['out'] !== 'undefined'"
      >
        <div class="title-params">
          Временно убывшие
        </div>
        <div class="title-params-item flex sulguni">
          {{ items['population']['out'] }}
        </div>
      </div>
    </div>
    <div
      v-if="isHouse"
      :key="componentKey"
    >
      <h2 class="taleggio mb-6">
        Дом
      </h2>
      <div
        class="title-conteiner flex sulguni"
        v-if="typeof items['buildingType'] !== 'undefined'"
      >
        <div class="title-params">
          Тип
        </div>
        <div class="title-params-item flex sulguni">
          {{ items['buildingType'] }}
        </div>
      </div>
      <div
        class="title-conteiner flex sulguni"
        v-if="typeof items['walls'] !== 'undefined'"
      >
        <div class="title-params">
          Материал стен
        </div>
        <div class="title-params-item flex sulguni">
          {{ items['walls'] }}
        </div>
      </div>
      <div
        class="title-conteiner flex sulguni"
        v-if="typeof items['built'] !== 'undefined'"
      >
        <div class="title-params">
          Год постройки
        </div>
        <div class="title-params-item flex sulguni">
          {{ items['built'] }}
        </div>
      </div>
      <div
        class="title-conteiner flex sulguni"
        v-if="typeof items['inService'] !== 'undefined'"
      >
        <div class="title-params">
          Год введения в эксплуатацию
        </div>
        <div class="title-params-item flex sulguni">
          {{ items['inService'] }}
        </div>
      </div>
      <div
        class="title-conteiner flex sulguni"
        v-if="typeof items['wearPercent'] !== 'undefined'"
      >
        <div class="title-params">
          Стадия использования
        </div>
        <div class="title-params-item flex sulguni">
          {{ items['wearPercent'] }}
        </div>
      </div>
      <div
        class="title-conteiner flex sulguni"
        v-if="typeof items['Серия дома'] !== 'undefined'"
      >
        <div class="title-params">
          Серия
        </div>
        <div class="title-params-item flex sulguni">
          {{ items['Серия дома'] }}
        </div>
      </div>
      <div
        class="title-conteiner flex sulguni"
        v-if="typeof items['floorOver'] !== 'undefined'"
      >
        <div class="title-params">
          Надземных этажей
        </div>
        <div class="title-params-item flex sulguni">
          {{ items['floorOver'] }}
        </div>
      </div>
      <div
        class="title-conteiner flex sulguni"
        v-if="typeof items['floorUnder'] !== 'undefined'"
      >
        <div class="title-params">
          Подземных этажей
        </div>
        <div class="title-params-item flex sulguni">
          {{ items['floorUnder'] }}
        </div>
      </div>
      <div
        class="title-conteiner flex sulguni"
        v-if="typeof items['porch'] !== 'undefined'"
      >
        <div class="title-params">
          Подъездов
        </div>
        <div class="title-params-item flex sulguni">
          {{ items['porch'] }}
        </div>
      </div>
      <div
        class="title-conteiner flex sulguni"
        v-if="typeof items['invalidFriendly'] !== 'undefined'"
      >
        <div class="title-params">
          Приспособлено для малоподвижной категории
        </div>
        <div class="title-params-item flex sulguni">
          {{ items['invalidFriendly'] }}
        </div>
      </div>
      <div
        class="title-conteiner flex sulguni"
        v-if="typeof items['elevator'] !== 'undefined'"
      >
        <div class="title-params">
          Лифты
        </div>
        <div class="title-params-item flex sulguni">
          {{ items['elevator'] }}
        </div>
      </div>
      <div
        class="title-conteiner flex sulguni"
        v-if="typeof items['balcony'] !== 'undefined'"
      >
        <div class="title-params">
          Балконы
        </div>
        <div class="title-params-item flex sulguni">
          {{ items['balcony'] }}
        </div>
      </div>
      <div
        class="title-conteiner flex sulguni"
        v-if="typeof items['loggia'] !== 'undefined'"
      >
        <div class="title-params">
          Лоджии
        </div>
        <div class="title-params-item flex sulguni">
          {{ items['loggia'] }}
        </div>
      </div>
      <div
        class="title-conteiner flex sulguni"
        v-if="typeof items['wearPercent'] !== 'undefined'"
      >
        <div class="title-params">
          Процент износа
        </div>
        <div class="title-params-item flex sulguni">
          {{ items['wearPercent'] }}
        </div>
      </div>
    </div>
    <div
      v-if="isRoom"
      :key="componentKey"
    >
      <h2 class="taleggio mb-6">
        Помещения и площади
      </h2>
      <div
        class="title-conteiner flex sulguni"
        v-if="typeof items['livingRoom'] !== 'undefined'"
      >
        <div class="title-params">
          Жилые помещения
        </div>
        <div class="title-params-item flex sulguni">
          {{ items['livingRoom'] }}
        </div>
      </div>
      <div
        class="title-conteiner flex sulguni"
        v-if="typeof items['nonResidental'] !== 'undefined'"
      >
        <div class="title-params">
          Нежилые помещения
        </div>
        <div class="title-params-item flex sulguni">
          {{ items['nonResidental'] }}
        </div>
      </div>
      <div
        class="title-conteiner flex sulguni"
        v-if="typeof items['buildingSquare'] !== 'undefined'"
      >
        <div class="title-params">
          Общая площадь
        </div>
        <div class="title-params-item flex sulguni">
          {{ items['buildingSquare'] }}
        </div>
      </div>
      <div
        class="title-conteiner flex sulguni"
        v-if="typeof items['livingSquare'] !== 'undefined'"
      >
        <div class="title-params">
          Жилая площадь
        </div>
        <div class="title-params-item flex sulguni">
          {{ items['livingSquare'] }}
        </div>
      </div>
      <div
        class="title-conteiner flex sulguni"
        v-if="typeof items['nonResidentalSquare'] !== 'undefined'"
      >
        <div class="title-params">
          Нежилая площадь
        </div>
        <div class="title-params-item flex sulguni">
          {{ items['nonResidentalSquare'] }}
        </div>
      </div>
      <div
        class="title-conteiner flex sulguni"
        v-if="typeof items['commonSquare'] !== 'undefined'"
      >
        <div class="title-params">
          Площадь общего пользования
        </div>
        <div class="title-params-item flex sulguni">
          {{ items['commonSquare'] }}
        </div>
      </div>
    </div>
    <div
      v-if="isCompany"
      :key="componentKey"
    >
      <h2 class="taleggio mb-6">
        Управляющая компания
      </h2>
      <div
        class="title-conteiner"
        v-if="typeof items['serviceTitle'] !== 'undefined'"
      >
        <div class="title-params">
          Наименование
        </div>
        <div class="title-params-item flex sulguni">
          {{ items['serviceTitle'] }}
        </div>
      </div>
      <div
        class="title-conteiner flex sulguni"
        v-if="typeof items['servicePhone'] !== 'undefined'"
      >
        <div class="title-params">
          Телефон
        </div>
        <div class="title-params-item flex sulguni">
          {{ items['servicePhone'] }}
        </div>
      </div>
      <div
        class="title-conteiner flex sulguni"
        v-if="typeof items['serviceAddress'] !== 'undefined'"
      >
        <div class="title-params">
          Адрес
        </div>
        <div class="title-params-item flex sulguni">
          {{ items['serviceAddress'] }}
        </div>
      </div>
      <div
        class="title-conteiner flex sulguni"
        v-if="typeof items['serviceUrl'] !== 'undefined'"
      >
        <div class="title-params">
          Ссылка
        </div>
        <div
          class="title-params-item flex sulguni"
          v-html="items['serviceUrl']"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'loadObject',
  props: {
    items: Array
  },
  data() {
    return {
      isHouse: false,
      isRoom: false,
      isCompany: false,
      isPeople: false,
      componentKey: 0
    };
  },
  mounted() {
    this.house();
    this.room();
    this.company();
    this.people();
  },
  methods: {
    async people() {
      const _this = this;
      _this.isPeople = false;
      const array = [
        'total',
        'in',
        'out'
      ];
      if (_this.items.population) {
        const keys = Object.keys(_this.items.population);
        keys.forEach(key => {
          array.forEach((value) => {
            if (value == key) {
              _this.isPeople = true;
            }
          });
        });
      }
      _this.componentKey += 1;
    },
    async house() {
      const _this = this;
      _this.isHouse = false;
      const array = [
        'buildingType',
        'walls',
        'built',
        'inService',
        'Серия дома',
        'floorOver',
        'floorUnder',
        'porch',
        'invalidFriendly',
        'elevator',
        'wearPercent',
        'loggia',
        'balcony'];
      const keys = Object.keys(_this.items);
      keys.forEach(key => {
        array.forEach((value, itemKey) => {
          if (value == key) {
            _this.isHouse = true;
          }
        });
      });
      _this.componentKey += 1;
    },
    async room() {
      const _this = this;
      _this.isRoom = false;
      const array = [
        'livingRoom',
        'nonResidental',
        'livingSquare',
        'buildingSquare',
        'nonResidentalSquare',
        'commonSquare'
      ];

      const keys = Object.keys(_this.items);
      keys.forEach(key => {
        array.forEach((value) => {
          if (value == key) {
            _this.isRoom = true;
          }
        });
      });
      this.componentKey += 1;
    },
    async company() {
      const _this = this;
      _this.isCompany = false;
      const array = [
        'serviceAddress',
        'serviceTitle',
        'serviceUrl',
        'servicePhone'
      ];

      const keys = Object.keys(_this.items);
      keys.forEach(key => {
        array.forEach((value) => {
          if (value == key) {
            _this.isCompany = true;
          }
        });
      });
      this.componentKey += 1;
    }
  }
};
</script>

<style lang="scss" scoped>
@mixin reset-list {
  font-style: normal;
  font-weight: normal;
  position: static;
}

.title-conteiner {
  padding-bottom: 16px;
  width: 100%;
  display: table;
}

.title-params {
  @include reset-list;
  right: 69.8%;
  top: 10%;
  bottom: 10%;
  font-size: 13px;
  line-height: 16px;
  color: #04153E;
  opacity: 0.48;
  float: left;
  width: 151px;
  max-width: 151px;
  margin-right: 24px;
}
</style>
