<template>
  <div
    class="save_modal"
  >
    <h1
      class="camembert mb-4"
    >
      Сохранить изменения?
    </h1>
    <p
      class="mb-8 parmigiano troy--text"
    >
      Вы внесли изменения в карточку уборки по адресу «{{ title }}»
    </p>
    <div
      class="mt-2 buttons"
    >
      <r-button
        title="Не сохранять"
        width="wide"
        class="flex-1"
        color="secondary"
        @click="close()"
      />
      <r-button
        title="Да, сохранить"
        width="wide"
        class="flex-1"
        @click.native="save"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'SavedModal',
  props: {
    saveFunc: Function,
    title: {
      type: String,
      default: 'Не указан'
    }
  },
  data() {
    return {
      upload: false
    };
  },
  methods: {
    close() {
      document.querySelectorAll('.RModal__close')[0].click();
    },
    async save() {
      this.saveFunc(this.items);
    }
  }
};
</script>

<style lang="scss" scoped>
.save_modal {
  display: initial;
  position: absolute;
  top: calc(50% - 150px);
  margin-left: 20%;
  width: 66%;
  height: 300px;
}

.buttons{
  display: grid;
  grid-template-columns: 380px 380px;
  grid-gap: 32px;
  @media(max-width: 792px ){
    grid-template-columns: 1fr;
  }
}
</style>
