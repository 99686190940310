<template>
  <div
    class="expand-photo"
  >
    <div
      @click="$emit('close')"
      class="expand-photo__close pointer"
      transparent
    >
      <r-icon
        icon="close"
        size="20"
        fill="rocky"
      />
    </div>

    <div
      class="expand-photo__wrapper"
    >
      <img
        :src="src"
        class="expand-photo__main-photo"
      >
    </div>
  </div>
</template>

<script>
export default {
  name: 'ExpandPhotoWrapperModal',
  props: {
    src: {
      type: String,
      required: true
    }
  },
  created() {
    document.body.style.overflow = 'hidden';
  },
  beforeDestroy() {
    document.body.style.overflow = 'auto';
  }
};
</script>

<style lang="scss" scoped>
.expand-photo {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--rir-amelie);
  z-index: 9999;
  overflow: auto;
  box-sizing: content-box;

  &__close {
    position: absolute;
    top: 17px;
    right: 17px;

    .rir-icon {
      height: 40px;
      width: 40px;
    }
  }

  &__wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 50px;
  }

  &__main-photo {
    height: 100%;
    border-radius: 15px;
  }
}
</style>
