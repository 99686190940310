<template />

<script>

export default {
  name: 'ModelSave',
  components: {
  },
  data() {
    return {
      upload: false,
      id: null,
      organizationIds: null,
      delete: null,
      json: null
    };
  },
  created() {
  },
  methods: {
    Close() {
      document.location.reload();
    }
  }
};
</script>

<style lang="scss" scoped>
.date {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #04153E;
  opacity: 0.48;
}

.align-center {
  align-items: center;
}

.upload {
  background-color: white;
  z-index: 999;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  overflow-y: scroll;
}
.upload::-webkit-scrollbar-track {
  width: 4px;
  background: #FFFFFF;
}

.upload::-webkit-scrollbar {
  width: 4px;
  background: #e4edfb;
  border-radius: 2px;
  opacity: 0.72;
}

.upload::-webkit-scrollbar-thumb {
  width: 4px;
  background: #e4edfb;
  border-radius: 2px;
  opacity: 0.72;
}

.parent {
  display: block;
  position: absolute;
  width: 40%;
  height: 400px;
  top: 33%;
  margin-left: 31%;
}
.margin-right {
  margin-top: 17px;
  margin-right: 17px;
  z-index: 1000;
  cursor: pointer;
}

.clear {
  height: 56px;
  background: #3D75E4;
  opacity: 0.08;
  border-radius: 8px;
}

.notSave {
  width: 100%;
  display: inline-block;
}
.objects {
  &__filter {
    display: flex;
  }
}
</style>
