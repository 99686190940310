<template>
  <div
    class="flex"
  >
    <div
      v-show="upload"
      class="upload"
    >
      <div>
        <div
          @click="upload = !upload"
        >
          <r-icon
            class="mr-2 margin-right"
            icon="close"
            size="20"
            fill="rocky"
          />
        </div>
        <div
          class="parent align-center"
        >
          <div
            class="container-upload"
          >
            <h1
              class="ricotta mb-1"
            >
              Комментарий
            </h1>
            <div
              class="date"
            >
              от {{ items['createdF'] }}
            </div>
            <r-textarea
              class="mb-6 mt-6"
              value=""
              label="Комментарий"
              :rows="6"
              :max-rows="8"
              v-model="items['comment']"
            />
            <div
              class="flex mt-2 mb-4"
              style="width: 100%;display: inline-block;"
            >
              <r-button
                width="wide"
                @click="save()"
                title="Предложить"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-show="uploadEnd"
      class="upload"
    >
      <div>
        <div
          @click="uploadEnd = !uploadEnd"
        >
          <r-icon
            class="mr-2 margin-right"
            icon="close"
            size="32"
          />
        </div>
        <div
          class="parent align-center"
        >
          <div
            class="container-upload"
          >
            <h1
              class="ricotta mb-1"
            >
              Итоги мероприятия
            </h1>
            <div
              class="date"
            >
              от {{ items['createdF'] }}
            </div>
            <r-textarea
              class="mb-6 mt-6"
              value=""
              label="Комментарий"
              :rows="6"
              :max-rows="8"
              v-model="items['commentResult']"
            />
            <upload-file-input
              :count-file="1"
              upload-url="/uploadfile/index.php"
              :value="filesUpload"
              :accept-file="['image/png', 'image/jpeg', 'image/gif']"
              @input="uploadFile"
              sub-title="или перетяните сюда файл объёмом не более 50 МБайт"
            />
            <div
              class="flex mt-2 mb-4"
              style="width: 100%;display: inline-block;"
            >
              <r-button
                width="wide"
                @click="saveEnd()"
                title="Предложить"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UploadFileInput from '@/components/files/UploadFileInput.vue';
import RirMap from '../maps/RirMap.vue';

export default {
  name: 'UploadModel',
  components: {
    RirMap,
    UploadFileInput
  },
  data() {
    return {
      expandImg: false,
      upload: false,
      uploadEnd: false,
      items: [],
      filesUpload: []
    };
  },
  methods: {
    formattedFiles(files) {
      const formattedFiles = [];
      files.forEach(el => {
        if (el?.files?.length) {
          const fileList = el.files.map(el => ({
            type: el.type,
            name: el.name, // .split('.')[0]
            size: el?.size,
            icon: false,
            url: el.url
          }));
          formattedFiles.push(...fileList);
        } else {
          formattedFiles.push(el);
        }
      });

      return formattedFiles;
    },
    uploadFile(files) {
      if (!files?.length) {
        this.filesUpload = [];
        this.items.photoResult0 = null;
        return;
      }
      this.filesUpload = this.formattedFiles(files);
      this.items.photoResult0 = this.filesUpload[0].url;
    },
    uploadContainer(items) {
      this.items = items;
      this.upload = true;
    },
    uploadContainerEnd(items) {
      this.items = items;
      this.uploadEnd = true;
    },
    saveEnd() {
      this.$store.dispatch('loadSave', this.items).then(result => {
        this.$store.dispatch('saveApprove', this.items).then(item => {
          const r = this.$router.resolve({
            name: 'clear-edit',
            params: { id: this.$route.params.id, bul: true }
          });

          if (!item?.error) {
            window.location.assign(r.href);
          }
        });
        const r = this.$router.resolve({
          name: 'clear-edit',
          params: { id: this.$route.params.id, bul: true }
        });

        if (!result?.error) {
          window.location.assign(r.href);
        }
      });
    },
    save() {
      this.$store.dispatch('saveComment', this.items).then(result => {
        this.$emit('save', this.items);
        this.upload = false;
      }, error => {
        console.error(error);
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.date {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #04153E;
  opacity: 0.48;
}

.align-center {
  align-items: center;
}

.upload {
  background-color: white;
  z-index: 99999;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  overflow-y: scroll;
}

.upload::-webkit-scrollbar-track {
  width: 4px;
  background: #FFFFFF;
}

.upload::-webkit-scrollbar {
  width: 4px;
  background: #e4edfb;
  border-radius: 2px;
  opacity: 0.72;
}

.upload::-webkit-scrollbar-thumb {
  width: 4px;
  background: #e4edfb;
  border-radius: 2px;
  opacity: 0.72;
}

.parent {
  display: block;
  position: absolute;
  width: 40%;
  height: 400px;
  top: 40%;
  margin-left: 31%;
  margin-top: -150px;
}

.margin-right {
  top: 10px;
  right: 10px;
  z-index: 1000;
  cursor: pointer;
  position: absolute;
}

.clear {
  height: 56px;
  background: #3D75E4;
  opacity: 0.08;
  border-radius: 8px;
}
</style>
