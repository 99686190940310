<template>
  <div>
    <div
      class="objects__list mt-6"
    >
      <div
        style="cursor: pointer"
        v-for="item in filteredObjects"
        :key="item.id"
      >
        <card
          ref="Card"
          :address="item.address"
          :house="item.dayF"
          :service-title="item.title"
          :photo0="item.photo0"
          :active-tab-id="activeTabId"
          :id="item.id"
          class="color-card"
        >
          <div
            class="flex"
          >
            <div
              class="mr-3 flex mozzarella"
            >
              <r-icon
                v-if="getStatus(item.dayF, item.dayFEnd, item.timeTo, item.timeFrom) === 'done'"
                icon="selected"
                fill="rocky"
                class="mr-2"
                size="16"
              />
              <r-icon
                v-if="getStatus(item.dayF, item.dayFEnd, item.timeTo, item.timeFrom) === 'progress'"
                icon="clock"
                class="mr-2"
                fill="rocky"
                size="16"
              />
              <r-icon
                v-if="getStatus(item.dayF, item.dayFEnd, item.timeTo, item.timeFrom) === 'wait'"
                icon="waiting"
                class="mr-2"
                fill="rocky"
                size="16"
              />
              <div
                class="text__date rocky--text"
              >
                {{ item.dayF !== '-' ? dateFilter(item.dayF) : item.dayF }}
              </div>
            </div>
            <div
              class="mr-1 flex mozzarella text__date rocky--text"
            >
              <r-icon
                class="mr-2"
                icon="message"
                size="16"
                fill="rocky"
              />
              {{ item.commentsAmount }}
            </div>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import Card from '@/components/cards/ObjectCardEvent.vue';
import Loader from '@/components/ states/Loader.vue';
import moment from 'moment';
import { wordMatch } from '../../helpers/utils';

export default {
  name: 'CardEvent',
  components: {
    Card,
    Loader
  },
  props: {
    allObjects: {
      type: Object,
      default: {}
    },
    activeTabId: {
      type: String,
      default: ''
    },
    isSearch: {
      type: String,
      default: ''
    },
    isSearchStatus: {
      type: String,
      default: ''
    },
    selectedCategory: {
      type: String,
      default: ''
    },
    selectedCategoryTo: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
    };
  },
  computed: {
    filteredObjects() {
      let list = this.allObjects;
      if (this.activeTabId === 'moderation') {
        list = list.filter(
          el => (el.approved == 1)
        );
      }
      if (this.activeTabId === 'moderationMod') {
        list = list.filter(
          el => (el.approved == 0)
        );
      }

      if (list && (this.selectedCategory || this.selectedCategoryTo)) {
        list = list.filter(el => moment(new Date(el.dayF)).set({
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0
        }).isBetween(moment(this.selectedCategory ? new Date(this.selectedCategory) : new Date(null)).set({
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0
        }), moment(this.selectedCategoryTo ? new Date(this.selectedCategoryTo) : new Date()).set({
          hour: 23,
          minute: 59,
          second: 59,
          millisecond: 59
        })) === true);
      }

      if (this.selectedCategoryStatus && this.activeTabId === 'moderation') {
        list = list.filter(el => this.getCleaningStatus(el.dayF) === this.selectedCategoryStatus);
      }
      if (this.isSearchStatus && this.activeTabId === 'moderation') {
        list = list.filter(el => this.getCleaningStatus(el.dayF) === this.isSearchStatus);
      }

      if (this.isSearch) {
        list = list.filter(el => wordMatch(el.address.toLowerCase(), this.isSearch.toLowerCase()) <= 0.6);
      }

      return list;
    }
  },
  methods: {
    dateFilter(e) {
      const date = new Date(e);
      let dd = date.getDate();
      if (dd < 10) dd = `0${dd}`;
      let mm = date.getMonth() + 1;
      if (mm < 10) mm = `0${mm}`;
      const yy = date.getFullYear();

      return `${dd}.${mm}.${yy}`;
    },
    getStatus(date, dateEnd, timeTo, timeFrom) {
      const toDay = new Date().toJSON().substring(0, 10); // 'yyyy-mm-dd'
      let from = 9;
      let to = 18;
      if (timeTo && timeFrom) {
        from = timeTo.split(':')[0];
        to = timeFrom.split(':')[0];
      }
      let status = 'wait';
      const hours = new Date().getHours();
      if (toDay >= date && toDay <= dateEnd) {
        status = 'progress';
        if (toDay === date) {
          if (hours < from) {
            status = 'wait';
          }
        }

        if (toDay === dateEnd) {
          if (hours < from) {
            status = 'progress';
          }
          if (hours > to) {
            status = 'done';
          }
        }
      }

      if (new Date(dateEnd) < new Date()) {
        status = 'done';
      }

      if(date === '-') {
        status = 'done';
      }

      return status;
    },
    formatDateHour(time) {
      let hh = time.split(':')[0];
      if (hh < 10) hh = hh.substring(1);
      const mm = time.split(':')[1];

      return `${hh}:${mm}`;
    }
  }
};
</script>

<style lang="scss" scoped>
.text__date {
  color: #0e1420;
  font-size: 13px;
  line-height: 16px;
  font-weight: 500;
}

.objects {
  &__list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 4px;

    > * {
      min-width: 0;
    }
  }
}

.color-card {
  color: #0e1420;
}
</style>
