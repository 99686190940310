<template>
  <div>
    <div
      class="contact-item flex align-center"
      v-for="(item, index) in list"
      v-if="item.hid == true || hidden == true"
      :key="index"
    >
      <div class="contact-item__text feta ml-4">
        <span
          class="contact-item__text feta"
          :style="item['delegate'] ? 'text-decoration: line-through' : ''"
        >{{ textValue ? item[textValue] : item }}</span>
      </div>
      <div
        class="contact-item__remove-btn ml-auto"
        @click="remove(index)"
      >
        <r-icon
          class="ml-2"
          icon="delete"
          fill="fargo"
          :size="iconSize"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContactItems',
  props: {
    icon: {
      type: String,
      default: 'telephone'
    },
    iconSize: {
      type: String,
      default: '16'
    },
    value: {
      type: Array,
      default: () => []
    },
    textValue: {
      type: [String, null],
      default: null
    },
    subValue: {
      type: String
    },
    hidden: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      list: []
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        this.list = val;
      }
    }
  },
  mounted() {
  },
  methods: {
    remove(index) {
      const list = this.list.filter((el, i) => i !== index);
      this.list = list;
      this.$emit('input', list);
    }
  }
};
</script>

<style lang="scss" scoped>
.contact-item {
  margin-bottom: 16px;
  &:last-child {
    margin-bottom: 0;
  }
  &__text {
    opacity: 0.72;
  }
  &__remove-btn {
    cursor: pointer;
    opacity: 0.72;
    &:hover {
      opacity: 1;
    }
  }
}
</style>
