<template>
  <div class="flex">
    <div class="upload">
      <div>
        <div class="parent align-center">
          <div class="container-upload ml-auto mr-auto">
            <r-icon
              icon="warning"
              fill="fargo"
              size="56"
            />
            <h1 class="mb-4 mt-7 camembert">
              Удалить уборку?
            </h1>
            <div class="mb-8 parmigiano troy--text">
              Все данные об уборке по адресу {{ address }} будут удалены.
            </div>
            <div class="mt-2 flex">
              <r-button
                title="Не удалять"
                class="flex-1 mr-4"
                :type="'secondary'"
                @click="close()"
              />
              <r-button
                title="Да, удалить"
                class="flex-1"
                @click.native.stop.prevent="Delete"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Api from '../api/PlaceApi';

export default {
  name: 'DeleteModal',
  props: {
    id: Number,
    actionType: String,
    title: String,
    address: String

  },
  data() {
    return {
      upload: false
    };
  },
  methods: {
    close() {
      document.querySelectorAll('.RModal__close').forEach(item => {
        item.click();
      });
    },
    async Delete() {
      let _this = this;
      const r = this.$router.resolve({
        name: 'index'
      });
      if (this.actionType === 'deleteClean') {
        const api = new Api();
        await api.deleteClean(this.id).then(result => {
          if (!result?.error) {
            window.location.assign(r.href);
            _this.close();
          }
        }).catch(err => {
          console.log('err', err);
        });
      }
      if (this.actionType === 'setDelete') {
        const api = new Api();
        await api.setDelete({ id: this.id }).then(result => {
          if (!result?.error) {
            window.location.assign(r.href);
          }
        }).catch(err => {
          console.log('err', err);
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.container-upload {
  width: 800px;
}
.upload {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
}
</style>
