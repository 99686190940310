export const wordMatch = (left, right) => {
  // 0 - полное совпадение
  // 1 - нет совпадений
  left = `\b\b${left}\f\f`;
  right = `\b\b${right}\f\f`;

  let dist = -4;

  for (let i = 0; i < left.length - 2; ++i) {
    if (!right.includes(left.slice(i, i + 3))) ++dist;
  }

  for (let i = 0; i < right.length - 2; ++i) {
    if (!left.includes(right.slice(i, i + 3))) ++dist;
  }

  return Math.max(0, dist) / (left.length + right.length - 8);
};
