<template>
  <div class="empty">
    <img
      src="../assets/empty.svg"
      alt=""
    >
    <p class="opacity-72 text-center text-c16 color-titanic mt-8px mb-5">
      Еще не добавлено ни одной точки сбора ТКО
    </p>
    <slot />
  </div>
</template>

<script>
export default {
  name: 'Empty'
};
</script>

<style scoped>
.empty{
  display: grid;
  justify-items: center;
  justify-content: center;
  margin-top: 150px;
}
</style>
