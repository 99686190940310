<template>
  <div class="loader flex align-center justify-center">
    <r-spinner />
  </div>
</template>

<script>
export default {
  name: 'Loader'
};
</script>

<style scoped lang="scss">
.loader {
  z-index: 10000;
  padding: 40px;
  width: 100%;
  height: 100%;
}

::v-deep .rocky {
  background-color: unset !important;
}
</style>
