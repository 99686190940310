<template>
  <div class="save_modal">
    <h1 class=" camembert mb-4">
      Ошибка
    </h1>
    <p class="mb-8 parmigiano troy--text">
      {{ title }}
    </p>
    <div class="mt-2 buttons">
      <r-button
        title="Понятно"
        width="wide"
        class="flex"
        color="secondary"
        @click="close()"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ErrorModal',
  props: {
    saveFunc: Function,
    title: {
      type: String,
      default: 'Не указан'
    }
  },
  data() {
    return {
      upload: false
    };
  },
  methods: {
    close() {
      document.querySelectorAll('.RModal__close')[0].click();
    }
  }
};
</script>

<style lang="scss" scoped>
.save_modal {
  display: initial;
  position: absolute;
  top: calc(50% - 150px);
  margin-left: 17%;
  width: 66%;
  height: 300px;
}

.buttons{
  @media(max-width: 792px ){
    grid-template-columns: 1fr;
  }
}
</style>
