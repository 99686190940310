import Vue from 'vue';
import Vuex from 'vuex';
import PlaceApi from '@/api/PlaceApi';

const api = new PlaceApi();

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    cleaningListSave: [],
    cleaningList: [],
    allCleaningData: {},
    cleaningListEdit: [],
    allCleaningDataEdit: {},
    // ----
    active: null,
    types: null,
    objects: null,
    selectedObject: null,
    save: null,
    saveNew: null,
    isObjectLoading: null,
    saveApprove: null,
    delete: null,
    comments: null,
    commentsSave: null,
    deleteComm: null,
    all: null,
    isEmpty: false,
    info: null,
    saveInfo: null
  },
  mutations: {
    setAll(state, objects) {
      state.all = objects;
    },
    setSaveInfo(state, objects) {
      state.saveInfo = objects;
    },
    setInfo(state, objects) {
      state.info = objects;
    },
    setDeleteComment(state, objects) {
      state.deleteComm = objects;
    },
    setCommentSave(state, objects) {
      state.commentsSave = objects;
    },
    setComments(state, objects) {
      state.comments = objects;
    },
    setDelete(state, objects) {
      state.delete = objects;
    },
    setActive(state, objects) {
      state.active = objects;
    },
    setSaveApprove(state, objects) {
      state.saveApprove = objects;
    },
    setCleaningList(state, list) {
      state.cleaningList = list;
    },
    setCleaningListEdit(state, list) {
      state.cleaningListEdit = list;
    },
    setCleaningListSave(state, list) {
      state.cleaningListSave = list;
    },
    setAllCleaningData(state, data) {
      state.allCleaningData = data;
    },
    // --===--
    setTypes(state, types) {
      state.types = types;
    },
    setSave(state, types) {
      state.save = types;
    },
    setSaveNew(state, types) {
      state.saveNew = types;
    },
    setObjects(state, objects) {
      state.objects = objects;
    },
    setSelectedObject(state, id) {
      state.selectedObject = { ...state.objects.filter(el => +el.id === +id)[0] } || null;
    },
    setObjectLoading(state, status) {
      state.isObjectLoading = status;
    },
    setIsEmpty(state, status) {
      state.isEmpty = status;
    }
  },
  getters: {
    getCleanById: state => id => state.cleaningListEdit.find(el => +el.id === +id),
    getTypes: () => state.objects.types,
    getObjectById: state => id => state.objects.find(el => el.id === id),
    getActive: () => state.objects,
    getSave: () => state.objects,
    getSaveNew: () => state.objects,
    getSaveApprove: () => state.objects,
    getDelete: () => state.objects,
    getComments: () => state.objects,
    getCommentSave: () => state.objects,
    getDeleteComment: () => state.objects,
    getAll: () => state.objects
  },
  actions: {
    async setCleaning(ctx, { data, id }) {
      if (!id) {
        const res = await api.setCleaning(data);
        return res;
      }
      const res = await api.changeClean({ id, ...data });
      return res;
    },

    async getCleaning(ctx, data) {
      ctx.commit('setObjectLoading', true);
      const res = await api.getCleaning(data);
      ctx.commit('setCleaningList', res.all || []);
      ctx.commit('setAllCleaningData', res);

      ctx.commit('setObjectLoading', false);
    },
    async getCleaningEdit(ctx, data) {
      ctx.commit('setObjectLoading', true);
      const res = await api.getCleaning(data);
      ctx.commit('setCleaningListEdit', res.all || []);
      ctx.commit('setAllCleaningData', res);

      ctx.commit('setObjectLoading', false);
    },
    async getCleaningSave(ctx, data) {
      const res = await api.getCleaning(data);
      ctx.commit('setCleaningListSave', res.all || []);
    },
    // ----===

    async selectObject(ctx, id) {
      if (!ctx.state.objects?.length) {
        const res = await api.getList({ readOnly: 1, type: 0, currentMainMenuNumber: 5 });
        ctx.commit('setObjects', res.all);
      }
      const selected = ctx.state.objects.find(el => el.id === id);
      ctx.commit('setSelectedObject', selected);
    },

    async loadObjects(ctx, typeId = 0) {
      ctx.commit('setObjectLoading', true);
      const res = await api.getList({ readOnly: 1, type: typeId, currentMainMenuNumber: 5 });
      ctx.commit('setObjects', res.all);
      ctx.commit('setObjectLoading', false);
      if (res.all.length < 1) {
        ctx.commit('setIsEmpty', true);
      }
    },

    async loadTypes(ctx) {
      const res = await api.getTypes();
      console.log('load types', res);
      ctx.commit('setTypes', res.types);
    },

    async restoreObject(ctx, id) {
      // SON: {id:Int, action: update|delete|save, item:{},coordinates:[Int,Int]}
      ctx.commit('setObjectLoading', true);
      const res = await api.setOperate({
        id,
        action: 'update',
        item: {
          purgeDeadline: null,
          hidden: '0'
        }
      });
      return res;
      console.log('delete res', res);
    },
    async deleteObject(ctx, id) {
      // SON: {id:Int, action: update|delete|save, item:{},coordinates:[Int,Int]}
      ctx.commit('setObjectLoading', true);
      const res = await api.setOperate({ id, action: 'delete' });
      console.log('delete res', res);
      return res;
    },
    async addObject(ctx, { item, coordinates }) {
      // SON: {id:Int, action: update|delete|save, item:{},coordinates:[Int,Int]}
      const payLoad = JSON.stringify({ action: 'update', coordinates, item });
      console.log('payLoad', payLoad);
      const res = await api.setOperate(payLoad);
      console.log('add object res', res);
      return res;
    },
    async loadActive(ctx) {
      const res = await api.getActive();
      ctx.commit('setActive', res);

      return res;
    },

    async loadActivity(ctx, paylod) {
      ctx.commit('setObjectLoading', true);
      const res = await api.getActivity(paylod);
      ctx.commit('setActive', res);
      ctx.commit('setObjectLoading', false);

      return res;
    },
    async loadSave(ctx, paylod) {
      const res = await api.setSave(paylod);
      ctx.commit('setSave', res);

      return res;
    },
    async saveApprove(ctx, paylod) {
      const res = await api.setSaveApprove(paylod);
      ctx.commit('setSave', res);

      return res;
    },
    async loadSaveNew(ctx, payload) {
      const res = await api.setSaveNew(payload);
      ctx.commit('setSaveNew', res);

      return res;
    },
    async loadDelete(ctx, payload) {
      const res = await api.setDelete(payload);
      ctx.commit('setDelete', res);

      return res;
    },
    async loadComments(ctx, payload) {
      const res = await api.getComments(payload);
      ctx.commit('setComments', res);

      return res;
    },
    async saveComment(ctx, payload) {
      const res = await api.getCommentSave(payload);
      ctx.commit('setCommentSave', res);

      return res;
    },
    async deleteComment(ctx, payload) {
      const res = await api.getDeleteComment(payload);
      ctx.commit('setDeleteComment', res);

      return res;
    },
    async loadAll(ctx, payload) {
      const res = await api.getAll(payload);
      ctx.commit('setAll', res.all || []);

      return res;
    },
    async loadInfo(ctx, payload) {
      const res = await api.getInfo(payload);
      ctx.commit('setInfo', res.all);

      return res;
    },
    async loadInfoSave(ctx, payload) {
      const res = await api.getSave(payload);
      ctx.commit('setSaveInfo', res.all);

      return res;
    }
  }
});
