import Vue from 'vue';
import VueRouter from 'vue-router';
import SaveObject from '@/views/SaveObject.vue';
import EditSaveObject from '@/views/EditSaveObject.vue';
import NewSaveObject from '@/views/NewSaveObject.vue';
import Admin from '../views/Admin.vue';
import ObjectList from '../views/ObjectList.vue';
import EditObject from '../views/EditObject.vue';
import NewObject from '../views/NewObject.vue';
import EditClearObject from '../views/EditClearObject.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'admin',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ '../views/Admin.vue')
    component: Admin,
    children: [
      {
        name: 'index',
        path: '',
        component: ObjectList
      },
      {
        name: 'save',
        path: 'save',
        component: ObjectList
      },
      {
        name: 'edit',
        path: 'edit/:id',
        component: EditObject
      },
      {
        name: 'new',
        path: 'new',
        component: EditObject
      },
      {
        name: 'new-clear',
        path: 'new-clear',
        component: NewObject
      },
      {
        name: 'save-objects',
        path: 'save-objects',
        component: SaveObject
      },
      {
        name: 'edit-objects',
        path: 'save-objects/edit/:id',
        component: EditSaveObject
      },
      {
        name: 'new-objects',
        path: 'save-objects/new/:type',
        component: NewSaveObject
      },
      {
        name: 'copy',
        path: 'copy',
        component: EditObject
      },
      {
        name: 'clear-edit',
        path: 'clear-edit/:id/:bul',
        component: EditClearObject
      }
    ]
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_BASE_URL,
  routes
});

export default router;
