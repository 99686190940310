<template>
  <div class="delete-warn">
    <div
      @click="$rir.modal.close()"
      class="delete-warn__close pointer mr-1"
      transparent
    >
      <r-icon
        icon="close"
        size="20"
        fill="rocky"
      />
    </div>

    <div class="delete-warn__wrapper">
      <h2 class="delete-warn__title">
        Уверены, что хотите удалить комментарий?
      </h2>
      <p class="delete-warn__text">
        Это действие невозможно будет отменить.
      </p>

      <div class="delete-warn__buttons">
        <r-button
          color="secondary"
          class="mr-4"
          width="wide"
          @click="$rir.modal.close()"
          title="Нет, оставить"
        />
        <r-button
          @click="deleteComm"
          width="wide"
          title="Да, удалить"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DeleteComment',

  props: {
    item: Object
  },

  created() {
    document.body.style.overflow = 'hidden';
  },

  beforeDestroy() {
    document.body.style.overflow = 'auto';
  },

  methods: {
    deleteComm(item) {
      const v = this;
      this.$store.dispatch('deleteComment', this.item).then(result => {
        this.$emit('save', item);
        const r = this.$router.resolve({
          name: 'clear-edit',
          params: { id: this.$route.params.id, bul: true }
        });
        if (!result?.error) {
          window.location.assign(r.href);
        }
      }, error => {
        console.error(error);
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.delete-warn {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: var(--rir-amelie);
  z-index: 999999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__close {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  &__wrapper {
    max-width: 720px;
    width: 100%;
  }

  &__title {
    font-size: 32px;
    margin-bottom: 12px;
  }

  &__text {
    opacity: 0.48;
  }

  &__buttons {
    display: flex;
    margin-top: 32px;

    .rir-button {
      white-space: nowrap;

      &:first-child {
        margin-right: 32px;
      }
    }
  }
}
</style>
<style>
.RButton.wide {
  width: 100% !important;
}
</style>
