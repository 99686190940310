<template>
  <div ref="content">
    <loading-content v-if="isLoadings" />
    <div :class="'button_fly'">
      <div
        class="flex align-center sulguni mb-7 rocky--text pointer"
        @click="linkClick()"
      >
        <r-icon
          class="mr-2 mt-2px"
          icon="arrow-left"
          fill="rocky"
          size="16"
        />
        Назад
      </div>
      <h1 class="ricotta">
        {{ $route.params.type == 'street' ? 'Новая улица' : ($route.params.type == 'yard' ? 'Новый двор' : 'Новая территория') }}
        <sup
          class="text-c13 fargo--text"
          v-if="!isSave || (name != null && name == '')"
        >Не сохранено</sup>
      </h1>

      <div>
        <div class="grid-cont mt-8">
          <div class="edit__form">
            <r-input
              class="flex-1 float custom"
              value=""
              style="width: 100%;"
              v-model="name"
              :label="$route.params.type == 'street' ? 'Название улицы' : ($route.params.type == 'yard' ? 'Название двора' : 'Название территории')"
              :params-input="{&quot;type&quot;:&quot;input&quot;}"
              @change="isChange"
            />
            <div class="mt-5 text-footer">
              Добавьте контрольные точки маршрута на карту
            </div>
          </div>

          <div class="edit__map">
            <rir-map-leaflet
              @clickMap="onMapClick"
              @mapInit="onMapInit"
              :street="$route.params.type == 'yard' ? true : false"
              :center="markers[0]?.position ? [markers[0].position.lat,this.markers[0].position.lng] : null"
              :key="countMap"
              collapse-btn
            >
              <div
                v-if="$route.params.type == 'yard'"
                :key="countMap"
              >
                <l-marker
                  v-for="marker in markers"
                  :visible="marker.visible"
                  :draggable="marker.draggable"
                  :lat-lng.sync="marker.position"
                >
                  <l-icon
                    :icon-url="$markerIconTwo.imageHref"
                  />
                </l-marker>
              </div>
            </rir-map-leaflet>
          </div>
        </div>
        <div class="button_container mt-10 work-form__btn-cont">
          <r-button
            class="flex-1"
            width="wide"
            title="Добавить"
            @click="submit"
            :disabled="isSave || name == null || name == ''"
          />
        </div>
      </div>
      <r-modal
        ref="modal"
        close-icon
        fullscreen
      />
    </div>
  </div>
</template>

<script>
import RirMapLeaflet from '@/components/RirMapLeaflet.vue';
import { LMarker, LIcon } from 'vue2-leaflet';
import { EditablePolyline } from 'vue2-leaflet-editable';
import PlaceApi from '../api/PlaceApi';

export default {
  components: {
    EditablePolyline,
    LMarker,
    LIcon,
    RirMapLeaflet
  },
  data() {
    return {
      markers: [],
      name: null,
      objectTypes: [
        { id: 'street', value: 'Улица' },
        { id: 'yard', value: 'Двор' }
      ],
      pol: null,
      streetGeometry: null,
      mapInstance: null,
      description: null,
      selectedCategory: 'street',
      address: null,
      isLoading: false,
      coordinates: null,
      geometry: [],
      isSave: true,
      countMap: 0,
      coords: true
    };
  },
  computed: {
    isLoadings() {
      return this.$store.state.isObjectLoading;
    }
  },
  watch: {
    markers: {
      deep: true,
      handler() {
        this.onDragMarker();
      }
    }
  },
  mounted() {
    const recaptchaScript = document.createElement('script');
    recaptchaScript.setAttribute(
      'src',
      'https://rawgit.com/Leaflet/Leaflet.Editable/master/src/Leaflet.Editable.js'
    );
    document.head.appendChild(recaptchaScript);
    const newMarker = {
      position: { lat: this.$cityCenter[0], lng: this.$cityCenter[1] },
      draggable: true,
      visible: true
    };
    if (this.markers.length == 0) {
      this.markers.push(newMarker);
    }
  },
  methods: {
    linkClick() {
      const r = this.$router.resolve({
        name: 'save-objects',
        params: {}
      });
      window.location.assign(r.href);
    },
    submit() {
      this.onSave();
    },
    onMapClick() {
      if (this.$route.params.type == 'street' || this.$route.params.type == 'territory') {
        this.isSave = false;
      }
    },
    isChange() {
      if (!this.streetGeometry
        || !this.coordinates
      ) {
        this.isSave = true;
      } else {
        this.isSave = false;
      }
    },
    onMapInit(e) {
      this.mapInstance = e;
      if (this.$route.params.type == 'street') {
        this.pol = this.mapInstance.mapObject.editTools.startPolyline();
      }
      if (this.$route.params.type == 'territory') {
        this.pol = this.mapInstance.mapObject.editTools.startPolygon();
      }
    },
    markersWasAdd(e) {
      if (!e[0].clusterName) {
        this.polyLine = e[0];
        e[0].editor.startDrawing();
      }
    },
    onDragMarker() {
      if (this.name != null && this.name != '') {
        this.isSave = false;
      } else {
        this.isSave = true;
      }
    },
    async onSave() {
      const _this = this;
      let lat = null;
      let lng = null;
      let territory = [];
      this.streetGeometry = [];
      let geometry = this.mapInstance.mapObject.editTools?._drawingEditor?._drawnLatLngs
        ? this.mapInstance.mapObject.editTools?._drawingEditor?._drawnLatLngs
        : this.pol?._latlngs;

      geometry = _this.$route.params.type == 'territory' ? geometry[0] : geometry;

      if (geometry != undefined) {
        for (var i = 0; i < geometry.length; i++) {
          const item = JSON.parse(
            String(geometry[i])
              .split('LatLng(')
              .join('[')
              .split(')')
              .join(']')
          );
          this.streetGeometry.push([item[0], item[1]]);
        }

        if(_this.$route.params.type == 'territory') {
          territory.push(_this.streetGeometry);
        }
      } else if (this.polyline) {
        if(_this.$route.params.type == 'territory') {
          let territory = [];
          for (let i = 0; i < items._latlngs[0].length; i++) {
            const item = JSON.parse(
              String(items._latlngs[0][i])
                .split('LatLng(')
                .join('[')
                .split(')')
                .join(']')
            );
            territory.push([item[0], item[1]]);
            lat = item[0];
            lng = item[1];
          }

          territory.push(_this.streetGeometry);
        } else {
          for (var i = 0; i < this.polyline._latlngs.length; i++) {
            const item = JSON.parse(
              String(this.polyline._latlngs[i])
                .split('LatLng(')
                .join('[')
                .split(')')
                .join(']')
            );
            this.streetGeometry.push([item[0], item[1]]);
            lat = item[0];
            lng = item[1];
          }
        }
      }
      let JSONS = {
        id: -1,
        action: 'update',
        currentMainMenuNumber: 110,
        cityId: '36',
        item: {
          type: this.$route.params.type == 'territory' ? 'territory' : 'street',
          address: this.name,
          geometry: _this.$route.params.type == 'territory' ? territory : this.streetGeometry,
          lat: null,
          lng: null
        }
      };
      if (this.$route.params.type === 'yard') {
        JSONS = {
          id: -1,
          action: 'update',
          currentMainMenuNumber: 110,
          cityId: '36',
          item: {
            type: 'yard',
            address: this.name,
            geometry: null,
            lat: this.markers[0].position.lat,
            lng: this.markers[0].position.lng
          }
        };
      }
      const api = new PlaceApi();
      await api.setSaveAdd(JSONS).then(result => {
        const r = _this.$router.resolve({
          name: 'save-objects',
          params: {}
        });

        if (!result?.error) {
          window.location.assign(r.href);
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
  .text-footer {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: #04153E;
    opacity: 0.48;
  }

  .button_container {
    margin-top: 30px;
    padding-top: 32px;
  }

  .button_fly {
    padding-bottom: 112px;

    .button_container {
      position: fixed;
      bottom: 0;
      right: 0;
      z-index: 15;
      width: 100%;
      max-width: calc(100% - 25.3%);
      background: #FFFFFF;
      box-shadow: 0px 8px 32px rgba(4, 21, 62, 0.16);
      padding-left: 31px;
      padding-right: 40px;
      padding-bottom: 40px;
    }
  }

  .dates {
    display: grid;
    grid-gap: 10px 0;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  .grid-cont {
    grid-column-gap: 24px;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .edit {
    &__map {
      width: 100%;
      overflow: hidden;
      height: 380px;
    }
  }

  .selected-dates {
    margin-top: 24px;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;

    &__container {
      font-size: 12px;
      background-color: var(--rir-alien);
      color: var(--rir-amelie);
      padding: 4px 8px;
      border-radius: 50px;
    }

    &__badge {
      padding: 4px 8px;
      border-radius: 30px;
      background-color: var(--rir-alien);
      color: var(--rir-amelie);
      font-size: 14px;
      display: flex;
      align-items: center;
      position: relative;
      overflow: hidden;

      &:hover .selected-dates__clear {
        opacity: 1;
        visibility: visible;
      }
    }

    &__clear {
      position: absolute;
      top: 0;
      right: 0;
      align-self: stretch;
      height: 100%;
      width: 40%;
      background-image: linear-gradient(90deg, transparent, var(--rir-alien) 70%);
      opacity: 0;
      visibility: hidden;
      transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;
      cursor: pointer;
    }
  }

  ::v-deep .rir-map__legend {
    display: none;
  }

  .work-form__btn-cont {
    position: fixed;
    right: 0;
    bottom: 0;
    width: 74.7%;
    background-color: #fff;
    z-index: 2;
    padding: 26px 40px 26px 32px;
    box-shadow: 0 9px 28px rgb(17 48 121 / 18%);
  }
</style>
<style>
.rir-date-picker__content {
  top: 143px !important;
  left: 62% !important;
}
</style>
