<template>
  <div
    ref="content"
    :class="{'button_fly': flyButton}"
  >
    <router-link
      class="flex align-center sulguni mb-7 print rocky--text"
      :to="{
        name: 'save',
      }"
    >
      <r-icon
        class="mr-2 mt-2px"
        icon="arrow-left"
        fill="rocky"
        size="16"
      />
      Назад
    </router-link>
    <loader v-if="isLoading || loadSave" />
    <div v-else>
      <div>
        <h1
          class="ricotta"
        >
          Новое мероприятие
        </h1>
      </div>
      <Loader v-if="isLoading" />
      <div
        v-else
        v-show="information"
        :key="counts"
      >
        <div class="grid-cont mt-8">
          <div class="block-hiden-too">
            <div>
              <r-input
                class="flex-1 custom"
                value=""
                v-model="items['title']"
                label="Название"
                :params-input="{&quot;type&quot;:&quot;input&quot;}"
              />
            </div>
            <div>
              <r-date-picker
                label="Дата"
                class="mt-5"
                v-model="itemsDef.date"
                @input="onClickDate"
                :is-input="false"
                :selected="cleaningDates"
                :error="!cleaningDates.length"
              />
              <div class="h-250">
                <div
                  class="selected-dates"
                  v-if="!!formatDates.length"
                >
                  <div
                    class="selected-dates__badge"
                    v-for="(date, index) in formatDates"
                    :key="index"
                  >
                    {{ date }}

                    <button
                      class="selected-dates__clear"
                      @click="clearDate(index)"
                    >
                      <r-icon
                        icon="close"
                        fill="amelie"
                        size="16"
                      />
                    </button>
                  </div>
                </div>
              </div>
              <div class="grid-time mt-5">
              <!--  <r-date-picker
                  label="Дата"
                  class="mr-6"
                  v-model="itemsDef.date"
                /> -->
                <r-time-picker
                  v-model="timeTo"
                  class="mr-6"
                  label="Время c"
                  :disabled="false"
                />
                <r-time-picker
                  v-model="timeFrom"
                  label="Время по"
                  :disabled="false"
                />
              </div>
            </div>
            <r-textarea
              class="mt-6"
              :value="items['comment']"
              label="Описание"
              :rows="6"
              :max-rows="6"
              v-model="items['comment']"
            />
            <!--  <h2 class="taleggio mt-6" style="margin-bottom: 16px">Обложка</h2>
              <div style="display: table;width: 100%;">
                <img :src="items['photo0']" class="rounded-8"
                       style="position: absolute; height: 56px;"/>
                <div style="float: right;" class="float-left mr-5 block pointer eye">
                  <rir-icon icon="delete" class="block" fill="#E14761"/>
                </div>
                <div class="clear">
                </div>
              </div> -->
            <h2
              class="taleggio mt-6 mb-4"
            >
              Обложка
            </h2>
            <upload-file-input
              :count-file="1"
              upload-url="/uploadfile/index.php"
              :value="filesUpload"
              :accept-file="['image/png', 'image/jpeg', 'image/gif']"
              @input="uploadFile"
              sub-title="или перетяните сюда файл объёмом не более 50 МБайт"
            />
          </div>
          <div class="edit__map">
            <rir-map-leaflet
              :legend="false"
              :center="coordinates || $cityCenter"
              collapse-btn
            >
              <l-marker
                v-for="marker in markers"
                :key="marker.id"
                :visible="marker.visible"
                :draggable="marker.draggable"
                :lat-lng.sync="marker.position"
              >
                <l-icon
                  :icon-url="$markerIconTwo.imageHref"
                />
              </l-marker>
            </rir-map-leaflet>
            <div class="message">
              Чтобы изменить положение на карте, перетащите точку
            </div>
            <div class="margin-info">
              <r-input
                class="flex-1 custom w-100"
                value=""
                v-model="itemsDef.address"
                label="Адрес"
                @keyup="onCoordinates(itemsDef.address)"
                :params-input="{&quot;type&quot;:&quot;input&quot;}"
              />
            </div>
          </div>
        </div>
        <div class="block-hiden block-hiden-one">
        </div>
        <div
          class="button_container mt-100"
        >
          <r-button
            width="wide"
            title="Сохранить"
            @click="save()"
            :disabled="cleaningDates.length === 0 || !items['title'] || !itemsDef.address"
          />
        </div>
      </div>
    </div>
    <r-modal
      ref="modal"
      close-icon
    />
  </div>
</template>

<script>
import Vue from 'vue';
import vClickOutside from 'v-click-outside';
import Loader from '@/components/ states/Loader.vue';
import UploadFileInput from '@/components/files/UploadFileInput.vue';
import { LMarker, LIcon } from 'vue2-leaflet';
import RirMapLeaflet from '@/components/maps/RirMapLeaflet.vue';
import { EditablePolyline } from 'vue2-leaflet-editable';
import PlaceApi from '@/api/PlaceApi';

Vue.use(vClickOutside);

export default {
  components: {
    EditablePolyline,
    LMarker,
    LIcon,
    RirMapLeaflet,
    UploadFileInput,
    Loader
  },
  props: {},
  data() {
    return {
      calendarDate: null,
      cleaningDates: [],
      mark: true,
      markers: [],
      file: [],
      isActive: Boolean,
      time: false,
      timeTo: '00:00',
      timeFrom: '00:00',
      itemsDef: {
        address: '',
        time: '',
        date: '',
        equipment: '',
        specialVehicle: '',
        equipmentReply: '',
        equipmentStatus: 'partiallyApproved',
        equipmentFiles: '',
        pecialVehicleReply: '',
        specialVehicleStatus: 'partiallyApproved',
        specialVehicleFiles: ''
      },
      hideImg: false,
      counts: 0,
      items: [],
      isLoad: false,
      address: null,
      coordinates: null,
      isButton: true,
      isCovid: Boolean,
      value: '',
      coors: false,
      loadSave: false,
      information: true,
      observation: false,
      flyButton: false,
      filesUpload: []
    };
  },
  computed: {
    // пред загрузка
    isLoading() {
      return this.$store.state.isObjectLoadingId;
    },
    formatDates() {
      return this.cleaningDates.map(date => {
        const nDate = new Date(date);
        return nDate.toLocaleDateString('RU-ru');
      });
    }
  },
  watch: {
    markers: {
      immediate: true,
      deep: true,
      handler() {
        if (this.mark) {
          this.onDragMarker();
        }
      }
    }
  },
  mounted() {
    if (this.$refs.content.offsetHeight > window.innerHeight) {
      this.flyButton = true;
    } else {
      this.flyButton = false;
    }
    this.items.photo0 = '';
    const newMarker = {
      position: { lat: this.$cityCenter[0], lng: this.$cityCenter[1] },
      draggable: true,
      visible: true
    };
    if (this.markers.length === 0) {
      this.markers.push(newMarker);
    }
    this.isLoad = false;
  },
  methods: {
    clearDate(index) {
      this.cleaningDates.splice(index, 1);
    },
    onClickDate(date) {
      if (!date) return;

      const index = this.cleaningDates.findIndex(el => el === date);

      if (index !== -1) {
        this.cleaningDates.splice(index, 1);
      } else {
        this.cleaningDates.push(date);
      }
    },
    formattedFiles(files) {
      const formattedFiles = [];
      files.forEach(el => {
        if (el?.files?.length) {
          const fileList = el.files.map(el => ({
            type: el.type,
            name: el.name, // .split('.')[0]
            size: el?.size,
            icon: false,
            url: el.url
          }));
          formattedFiles.push(...fileList);
        } else {
          formattedFiles.push(el);
        }
      });
      return formattedFiles;
    },
    uploadFile(files) {
      if (!files?.length) {
        this.filesUpload = [];
        this.items.photo0 = null;
        return;
      }
      this.filesUpload = this.formattedFiles(files);
      this.items.photo0 = this.filesUpload[0].url;
    },
    onClickOutside() {
      this.time = false;
    },
    hourAdd(item) {
      for (let i = 1; i <= 24; i++) {
        const hour = document.querySelectorAll(`div.hour${i}`)[0];
        if (item === i) {
          hour.style.background = '#3D75E4';
          hour.style.color = '#FFFFFF';
          hour.style.borderRadius = '5px';
        } else {
          hour.style.background = '';
          hour.style.color = '';
        }
      }
    },
    minutesAdd(item) {
      for (let i = 1; i <= 24; i++) {
        const minutes = document.querySelectorAll(`div.minutes${i}`)[0];
        if (item === i) {
          minutes.style.background = '#3D75E4';
          minutes.style.color = '#FFFFFF';
          minutes.style.borderRadius = '5px';
        } else {
          minutes.style.background = '';
          minutes.style.color = '';
        }
      }
    },
    async onDragMarker(e) {
      const api = new PlaceApi();
      const geo = await api.getGeoSearch({ lat: this.markers[0].position.lat, lng: this.markers[0].position.lng });

      const { address } = geo;
      this.mark = true;
      if (address) this.itemsDef.address = address;
    },
    async onCoordinates(item) {
      const api = new PlaceApi();
      const geo = await api.getGeoSearch({ address: item.trim() });

      const { lat, lng } = geo;
      this.mark = false;
      this.markers[0].position = { lat, lng };
      this.mark = true;
    },
    deletePhoto() {
      this.hideImg = false;
      this.items.photo0 = '';
    },
    onChangeFiles(files) {
      if (!files?.length) {
        this.files = [];
        return;
      }

      const formattedFiles = [];

      files.forEach(el => {
        if (el?.files?.length) {
          const fileList = el.files.map(el => ({
            contentLength: Number(el.size),
            contentType: el.type,
            filename: el.name,
            url: `${el.url}`
          }));
          formattedFiles.push(...fileList);
        } else {
          formattedFiles.push(el);
        }
      });
      this.hideImg = true;
      this.files = formattedFiles;
      this.items.photo0 = formattedFiles[0].url;
    },
    observationFunc() {
      this.information = false;
      this.observation = true;
    },
    inputCovid() {
      if (this.value !== '') {
        this.value = this.value.replace(/[^\d,]/g, '');
        this.isButton = false;
      }
    },
    valueCovid() {
      let itemsProcessed = 0;

      this.$store.state.loadId.covids.forEach((item, index, array) => {
        itemsProcessed++;
        this.value += item.porch;

        if (itemsProcessed !== array.length) {
          this.value += ',';
        }
        this.isCovid = false;
      });
    },
    async loadId(result) {
      this.address = result;
    },
    active() {
      const item = {
        active: !this.isActive,
        id: this.$store.state.loadId.id
      };
      this.$store.dispatch('loadActive', item).then(result => {
        if (typeof (result.updated) !== 'undefined') {
          this.isActive = !this.isActive;
        }
      });
    },
    funcCheck() {
      this.isCovid = !this.isCovid;
      this.isButton = false;
    },
    formatDateBack(time) {
      let hh = time.split(':')[0];
      if (hh < 10) hh = hh.substring(1);
      const mm = time.split(':')[1];

      return `${hh}:${mm}`;
    },
    save() {
      const item = {
        id: 0,
        title: this.items.title,
        eventForm: {
          datesExtra: this.cleaningDates,
          address: this.itemsDef.address,
          date: null,
          time: `${this.formatDateBack(this.timeTo)} - ${this.formatDateBack(this.timeFrom)}`,
          equipment: '',
          specialVehicle: '',

          equipmentReply: '',
          equipmentStatus: 'approved',
          equipmentFiles: '',

          specialVehicleReply: '',
          specialVehicleStatus: 'approved',
          specialVehicleFiles: ''
        },
        lat: this.markers[0].position.lat,
        lng: this.markers[0].position.lng,
        photo0: this.items.photo0,
        photo1: '',
        photo2: '',
        comment: this.items.comment,
        commentResult: '',
        photoResult0: '',
        photoResult1: '',
        photoResult2: '',
        approved: 0,
        eventStatus: 1
      };

      this.$store.dispatch('loadSaveNew', item).then(result => {
        const r = this.$router.resolve({
          name: 'save'
        });
        if (!result?.error) {
          window.location.assign(r.href);
        }
      }, error => {
        console.error(error);
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.button_container{
  margin-top: 30px;
  padding-top:32px;
}
.button_fly{
  padding-bottom: 112px;
  .button_container{
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 15;
    width: 100%;
    max-width: calc(100% - 25.3%);
    background: #FFFFFF;
    box-shadow: 0px 8px 32px rgba(4, 21, 62, 0.16);
    padding-left: 31px;
    padding-right: 40px;
    padding-bottom: 40px;
  }
}

.comment {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #04153E;
}
.title {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #04153E;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  margin: 4px 0px;
}

.title-text {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: #04153E;
  opacity: 0.48;
  padding-right: 12px;
}

.line {
  position: static;
  height: 4px;
  background: #3D75E4;
  border-radius: 2px;
  margin: 8px 0px;
}

.link-information {
  cursor: pointer;
  display: table;
}

.text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #04153E;
}

.clear {
  height: 56px;
  background: #3D75E4;
  opacity: 0.08;
  border-radius: 8px;
}

.obj-card {
  &__title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.grid-cont {
  grid-column-gap: 24px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
}

.edit {
  &__map {
    display: block;
    height: 400px;
  }
}

.margin-info {
  margin-top: 24px;
  width: 100%;
}

.block {
  fill: #E14761;
  color: #E14761;
  margin-top: 1px;
}

.text-covid {
  display: table;
  width: calc(100% - 174px)
}

@mixin reset-list {
  font-style: normal;
  font-weight: normal;
  position: static;
}

.title-params-item {
  @include reset-list;
  left: 35%;
  font-size: 16px;
  line-height: 20px;
  color: #04153E;
  display: table;
}

.street-house {
  margin-top: 13px;
  @include reset-list;
  width: 100%;
  height: 30px;
  left: 24px;
  font-size: 16px;
  line-height: 20px;
  color: #04153E;
}

.print {
  fill: #3D75E4;
  color: #3D75E4;
}

.pointer {
  cursor: pointer;
}

.eye {
  cursor: pointer;
  margin-top: 36px;
}

.block {
  fill: #E14761;
  color: #E14761;
  margin-top: 1px;
}

.message {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: #000000;
  opacity: 0.48;
  margin-top: 5px;
}

.block-hiden-one {
  margin-top: 40px;
}

.margin-right {
  margin-right: 12px;
}

.icon-margin {
  position: absolute;
  margin-top: -2px;
  margin-left: -10px;
}

.icon-placeholder {
  display: inline-block; /* Строчно-блочный элемент */
  position: relative; /* Относительное позиционирование */
}

.icon-placeholder:hover::after {
  content: attr(data-title); /* Выводим текст */
  position: absolute; /* Абсолютное позиционирование */
  left: -1000%;
  top: 130%; /* Положение подсказки */
  z-index: 1; /* Отображаем подсказку поверх других элементов */
  width: 190px;
  height: 40px;
  background: #FFFFFF;
  border-radius: 12px;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: #04153E;
  padding-top: 4px;
  padding-left: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.schedule {
  display: flex;
}

.w-100 {
  width: 100%;
}

.mt-100 {
  margin-top: 100px;
}

.selected-dates {
  margin-top: 24px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  &__container {
    font-size: 12px;
    background-color: var(--rir-alien);
    color: var(--rir-amelie);
    padding: 4px 8px;
    border-radius: 50px;
  }

  &__badge {
    padding: 4px 8px;
    border-radius: 30px;
    background-color: var(--rir-alien);
    color: var(--rir-amelie);
    font-size: 14px;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;

    &:hover .selected-dates__clear {
      opacity: 1;
      visibility: visible;
    }
  }

  &__clear {
    position: absolute;
    top: 0;
    right: 0;
    align-self: stretch;
    height: 100%;
    width: 40%;
    background-image: linear-gradient(90deg, transparent, var(--rir-alien) 70%);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;
    cursor: pointer;
  }
}

.grid-time {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
</style>
<style>
.rir-date-picker__time__stat>div {
  display: flex;
  flex-direction: column;
  width: 50%;
  overflow: auto;
}
</style>
